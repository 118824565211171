import { Controller } from 'stimulus';
import { post } from "@rails/request.js";

export default class extends Controller {
    static targets = [
        'period',
        'monthlyValue',
        'plan',
        'planValue',
        'addon',
        'addonPrice',
        'extra',
        'extraPrice',
        'signButton',
        'checkoutExtras',
        'checkoutAddons',
        'checkoutExtraRow',
        'checkoutAddonRow',
        'checkoutMonthlyValue',
        'checkoutPlanSelected',
        'checkoutPlanSelectedValue',
        'checkoutPlanSelectedPeriod',
        'checkoutPlanSelectedValueDiscount',
        'formPlan',
        'formContractedPeriod',
        'formPrice',
        'formDiscountPercentage',
        'submitButton',
        'invoiceItemsForm'
    ]
    static values = {
        url: String
    }

    updateMonthlyValue(){
				if (!this.hasSelectedPlan()) return
        let plans_sum_prices = this.planTargets.filter((planTarget) => planTarget.checked == true).reduce((sum, plan) => sum + parseFloat(plan.dataset.price), 0)
        let addon_sum_prices = this.addonTargets.filter((addonTarget) => addonTarget.checked == true).reduce((sum, addon) => sum + parseFloat(addon.dataset.price), 0)
        let extras_sum_price = this.extraTargets.reduce((sum, extra) => sum + this.extraPrice(extra), 0)
        
        let period = this.active_period()
        let time = parseInt(period.dataset.time)
        let discount = parseFloat(period.dataset.discount)

        let total_value = ((plans_sum_prices + extras_sum_price + addon_sum_prices) * time)
        let discount_value = (total_value * discount)
        let monthly_value = (total_value - discount_value ) / time

        this.monthlyValueTarget.innerHTML = this.price_format(monthly_value)
    }

    updateValue(){
        this.updateMonthlyValue()
    }

    updatePrice(event) {
        let target = event.target
        let quantity = ( target.value - target.min)
        let price_value = parseFloat(target.dataset.price)
        const extra_price = this.extraPriceTargets.find(extraPrice => extraPrice.dataset.id == target.dataset.id)
        extra_price.innerHTML = this.price_format(( price_value * quantity))
        this.updateMonthlyValue()
    }

    extraPrice(target) {
        let quantity = ( target.value - target.min)
        let price_value = parseFloat(target.dataset.price)
        return price_value * quantity
    }

    active_period() {
        let period = this.periodTargets.find( period => period.checked === true)
        return period
    }

    price_format(value) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })
        return formatter.format(value)
    }

    enableButton() {
        if (this.signButtonTarget.disabled) this.signButtonTarget.removeAttribute('disabled')
    }

    renderCheckoutData() {
        let selectedPlan = this.selectedPlan()
        let period = this.active_period()
        let extras_sum_price = this.extraTargets.reduce((sum, extra) => sum + this.extraPrice(extra), 0)

        let total_value = (parseFloat(selectedPlan.dataset.price) + extras_sum_price) * period.dataset.time
        let discount = total_value * parseFloat(period.dataset.discount)
        this.checkoutMonthlyValueTarget.innerHTML = this.price_format(total_value - discount)
        
        this.cleanCheckoutData()

        this.renderCheckoutExtras()
        this.renderCheckoutAddons()
        this.renderCheckoutPlan(period, selectedPlan)
        this.renderCheckoutFormData(period, selectedPlan)
    }

    renderForm() {
        let selectedPlan = this.selectedPlan()
        if (!selectedPlan) return

        let period = this.active_period()
        let planId = selectedPlan.dataset.planId
        let body = new FormData()

        body.append('plan_id', planId)
        body.append('contracted_period', period.dataset.time)
        body.append('price', selectedPlan.getAttribute('data-price'))
        body.append('discount_percentage', parseFloat(period.dataset.discount))

        post(`${planId}${this.urlValue}`, {
            body: body,
            responseKind: "turbo-stream"
        })
    }

    renderCheckoutFormData(period, selectedPlan) {
        let contractedPeriodDate = new Date()

        contractedPeriodDate.setMonth(contractedPeriodDate.getMonth() + parseInt(period.dataset.time))

        this.formPlanTarget.value = selectedPlan.getAttribute('data-label')
        this.formContractedPeriodTarget.value = `${contractedPeriodDate.toLocaleDateString('pt-BR')} - ${period.value}`
        this.formPriceTarget.value = this.price_format(selectedPlan.getAttribute('data-price'))
        this.formDiscountPercentageTarget.value = `${parseFloat(period.dataset.discount) * 100}%`
        this.submitButtonTarget.setAttribute('form', `new_invoice_${selectedPlan.dataset.planId}`)


        let form_index = 0;

        if (!this.hasExtraTarget) return

				const validAddonTargets = this.addonTargets.filter((addonTarget) => addonTarget.checked == true);
        const validExtraTargets = this.extraTargets.filter((extraTarget) => extraTarget.value !== extraTarget.min);

				validAddonTargets.forEach((addonTarget) => {
					this.renderInvoiceItem({addonTarget: addonTarget, form_index: form_index});
					form_index++
			
				});
       
				validExtraTargets.forEach((extraTarget) => {
            this.renderInvoiceItem({extraTarget: extraTarget, form_index: form_index});
						form_index++
        });
    }


    renderInvoiceItem({addonTarget = null, extraTarget= null, form_index = 0}) {

        let name = document.createElement('input')
        name.setAttribute('autocomplete', 'off');
        name.setAttribute('type', 'hidden');
        name.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][name]`);
        name.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_name`);

				let quantity = document.createElement('input')
				let planItem = document.createElement('input')
				let addon = document.createElement('input')


        if(addonTarget !== null){
          addon.setAttribute('autocomplete', 'off');
				  addon.setAttribute('type', 'hidden');
          addon.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][addon_id]`);
          addon.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_addon_id`);
					name.value = addonTarget.dataset.label
					addon.value = addonTarget.dataset.id

					this.invoiceItemsFormTarget.append(name, addon)
        }
					
        if(extraTarget !== null){
          quantity.setAttribute('autocomplete', 'off');
        	quantity.setAttribute('type', 'hidden');
        	quantity.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][quantity]`);
        	quantity.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_quantity`);

					planItem.setAttribute('autocomplete', 'off');
          planItem.setAttribute('type', 'hidden');
          planItem.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][plan_item_id]`);
          planItem.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_plan_item_id`);
					name.value = extraTarget.dataset.name
					quantity.value = extraTarget.value - parseInt(extraTarget.getAttribute('min'))
					planItem.value = extraTarget.dataset.id					

					this.invoiceItemsFormTarget.append(name, quantity, planItem)
        }
    }

    renderCheckoutPlan(period, selectedPlan) {
        this.checkoutPlanSelectedTarget.innerHTML = selectedPlan.getAttribute('data-label')
        this.checkoutPlanSelectedValueTarget.innerHTML = this.price_format(selectedPlan.getAttribute('data-price'))
        this.checkoutPlanSelectedPeriodTarget.innerHTML = period.value
        this.checkoutPlanSelectedValueDiscountTarget.innerHTML = `${parseFloat(period.dataset.discount) * 100}%`
    }

    renderCheckoutAddons() {
        if (!this.hasAddonTarget) return false

        this.addonTargets.map((addon) => {
          if (addon.checked != true) return false
          
          let row = document.createElement('tr')
          row.setAttribute('data-billing-target', 'checkoutAddonRow')
          row.setAttribute('class', 'capitalize bg-white border-b dark:bg-gray-800 dark:border-gray-700')

          let nameLine = document.createElement('td')
          nameLine.innerHTML = addon.getAttribute('data-label')
          nameLine.setAttribute('class', 'px-4 py-3' )
          row.appendChild(nameLine);

          let valueLine = document.createElement('td')
          valueLine.innerHTML = this.price_format(this.extraPrice(addon))
          valueLine.setAttribute('class', 'px-4 py-3' )
          row.appendChild(valueLine)

          this.checkoutAddonsTarget.insertAdjacentElement("afterend", row)
        })

    }

    renderCheckoutExtras() {
        if (!this.hasExtraTarget) return false

        this.extraTargets.map((extraTarget) => {
            if (extraTarget.value === extraTarget.min) return false

            let row = document.createElement('tr')
            row.setAttribute('data-billing-target', 'checkoutExtraRow')
            row.setAttribute('class', 'capitalize bg-white border-b dark:bg-gray-800 dark:border-gray-700')

            let nameLine = document.createElement('td')
            nameLine.innerHTML = `${extraTarget.getAttribute('data-name')} (${extraTarget.value - extraTarget.getAttribute('min')}) `
            nameLine.setAttribute('class', 'px-4 py-3' )
            row.appendChild(nameLine);

            let valueLine = document.createElement('td')
            valueLine.innerHTML = this.price_format(this.extraPrice(extraTarget))
            valueLine.setAttribute('class', 'px-4 py-3' )
            row.appendChild(valueLine)

            this.checkoutExtrasTarget.appendChild(row)
        })
    }


    hasSelectedPlan() {
      if (!this.hasPlanTarget) return false

			if (this.planTargets.some(x => x.checked)) return true
			
			return false
    }

    selectedPlan() {
        if (!this.hasPlanTarget) return false

        return this.planTargets.find((planTarget) => {
            return planTarget.checked;
        });
    }

    cleanCheckoutData() {
        if (!this.hasCheckoutExtraRowTarget) return false

        this.checkoutExtraRowTargets.forEach((checkoutExtraRow) => {
            checkoutExtraRow.remove()
        })

        this.checkoutAddonRowTargets.forEach((row) => {
            row.remove()
        })
    }
}
