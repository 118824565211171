import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["measurement", "optionMeasure", "optionVehicle"]

  initialize(){
    this.selectUnits();
  }

  selectUnits(){
    this.disabledOptions();
    this.clearOptions();
    this.fixedOptions();

    this.measurementTargets.forEach(measurement => {
      if(measurement.value == 'metric' && measurement.checked == true){
        this.optionMetricUnit();
      }
    });

    this.measurementTargets.forEach(measurement => {
      if(measurement.value == 'imperial' && measurement.checked == true){
        this.optionImperialUnit();
      }
    });
  }

  optionMetricUnit(){
    this.optionMeasureTargets.forEach(option => {
      if(option.value == 'liters'){
        option.checked = true
      }
    });

    this.optionVehicleTargets.forEach(optionVehicle => {
      if(optionVehicle.value == 'kilometers'){
        optionVehicle.checked = true
      }
    });
  }

  optionImperialUnit(){
    this.optionMeasureTargets.forEach(option => {
      if(option.value == 'galons'){
        option.checked = true
      }
    });

    this.optionVehicleTargets.forEach(optionVehicle => {
      if(optionVehicle.value == 'miles'){
        optionVehicle.checked = true
      }
    });
  }

  fixedOptions(){
    this.optionMeasureTargets.forEach(fixedOption => {
      if(fixedOption.value == 'cubic_meters'){
        fixedOption.checked = true
      }
    });

    this.optionMeasureTargets.forEach(fixedOption => {
      if(fixedOption.value == 'kilowatt'){
        fixedOption.checked = true
      }
    });
  }

  clearOptions(){
    this.optionMeasureTargets.forEach(clearOption => {
      clearOption.checked = false
    });
  }

  disabledOptions(){
    this.measurementTargets.forEach(measurement => {
      if(measurement.value == 'metric' && measurement.checked == true){
        document.getElementById('tenant_config_attributes_gas_measure_liters').disabled = false;
        document.getElementById('tenant_config_attributes_gas_measure_galons').disabled = true;

        document.getElementById('tenant_config_attributes_vehicle_measure_kilometers').disabled = false;
        document.getElementById('tenant_config_attributes_vehicle_measure_miles').disabled = true;
      }
    });

    this.measurementTargets.forEach(measurement => {
      if(measurement.value == 'imperial' && measurement.checked == true){
        document.getElementById('tenant_config_attributes_gas_measure_galons').disabled = false;
        document.getElementById('tenant_config_attributes_gas_measure_liters').disabled = true;

        document.getElementById('tenant_config_attributes_vehicle_measure_miles').disabled = false;
        document.getElementById('tenant_config_attributes_vehicle_measure_kilometers').disabled = true;
      }
    });
  }
}