import { Application } from '@hotwired/stimulus';
import { Tabs, Dropdown, Slideover  } from 'tailwindcss-stimulus-components';
import AccordionController from '@kanety/stimulus-accordion';
import Notification from 'stimulus-notification';
import NestedForm from 'stimulus-rails-nested-form';

window.Stimulus = Application.start();

import controllers from "./*_controller.js";
controllers.forEach((controller) => {
    Stimulus.register(controller.name, controller.module.default)
});

Stimulus.register('notification', Notification);
Stimulus.register('dropdown', Dropdown);
Stimulus.register('tabs', Tabs);
Stimulus.register('slideover', Slideover);
Stimulus.register('accordion', AccordionController);
Stimulus.register('nested-form', NestedForm);
